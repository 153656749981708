<template lang="pug">
  .container.section

    .columns
      .column.is-12
        .flex-head
          h1.title User List
          button.button(@click="addAvoPrice") Add 🥑 Price
        .box
          .columns.is-vcentered
            .column.is-6
              b-input(v-model="filterByEmail" placeholder="Filter by Email" @keydown.native.enter="filterResults")
            .column.is-6
              b-select(v-model="filterMode" @change.native="filterResults")
                option(value="") Show All
                option(value="paymentIssue") Payment Issues
                option(value="paymentNeeded") Payment Incomplete
          b-table(:data="users" backend-sorting :default-sort-direction="defaultSortOrder" :default-sort="[sortField, sortOrder]" @sort="onSort")
            b-table-column(field="email" label="Email" sortable v-slot="props")
              | {{ props.row.email }}
              b-icon.is-admin(size='is-small' type='is-warning' icon='star' v-if="props.row.isAdmin" title="Admin User")
              b-icon.needs-verify(size='is-small' type='is-info' icon='envelope' v-if="!props.row.emailVerified && !props.row.emailInvalid" title="Needs Verification")
              b-icon.is-invalid(size='is-small' type='is-danger' icon='envelope' v-if="props.row.emailInvalid" title="Email Address Invalid")
            b-table-column(field="plan" label="Plan" width='180' v-slot="props")
              a.plan-name(v-if="props.row.stripeId" target="_blank" :href="stripeCustomerURI+props.row.stripeId" title="View in Stripe Dashboard") {{props.row.plan}}
              span.plan-name(v-if="!props.row.stripeId") {{ props.row.plan }}
              span.plan-currency(v-if="props.row.billingCurrency") &nbsp;({{props.row.billingCurrency.toUpperCase()}})
              b-icon.payment-issue(size='is-small' type='is-info' icon='exclamation-circle' v-if="props.row.paymentState == 'needed'" title="Payment Incomplete")
              b-icon.payment-issue(size='is-small' type='is-danger' icon='exclamation-circle' v-if="props.row.paymentState == 'failed'" title="Payment Issue")
              b-icon.free-upgrade(size='is-small' type='is-info' icon='level-up-alt' v-if="props.row.freeUpgrade" title="Free Upgrade")
            b-table-column(field="createTimestamp" label="Signed up" sortable width='200' v-slot="props")
              | {{ props.row.createTimestamp | time }}
            b-table-column(label="Actions" width='200' v-slot="props")
              b-dropdown(aria-role="list")
                button.button(slot="trigger")
                  span Choose an action...
                  b-icon.down-caret(icon="caret-down")
                b-dropdown-item(aria-role="listitem" @click="upgradeToLifetime(props.row)" v-if="props.row.plan != 'lifetime'")
                  | Free Lifetime Upgrade
                b-dropdown-item(aria-role="listitem" @click="downgradeToFree(props.row)" v-if="props.row.plan != 'starter'")
                  | Downgrade to Free
                b-dropdown-item(aria-role="listitem" @click="creditAccount(props.row)" v-if="canCreditAccount(props.row)")
                  | Give Stripe Credit
                b-dropdown-item(aria-role="listitem" @click="resetEmail(props.row)" v-if="!props.row.isAdmin")
                  | Change Email Address
                b-dropdown-item(aria-role="listitem" @click="markEmailValid(props.row)" v-if="!props.row.isAdmin && props.row.emailInvalid")
                  span.has-text-primary Mark Email Valid
                b-dropdown-item(aria-role="listitem" @click="markEmailInvalid(props.row)" v-if="!props.row.isAdmin && !props.row.emailInvalid")
                  span.has-text-danger Mark Email Invalid
          infinite-loading(:identifier="infiniteId" @infinite="loadNextPage")
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import firebase from 'firebase/app'
import dayjs from '@/dayjs'
import firestore from '@/firebase/async-firestore'

const stripeCustomerURI = process.env.VUE_APP_STRIPE_PK.match(/^pk_test_/)
  ? 'https://dashboard.stripe.com/test/customers/'
  : 'https://dashboard.stripe.com/customers/'

export default {
  name: 'AdminHome',
  components: { InfiniteLoading },
  filters: {
    time(stamp) {
      return dayjs(stamp.toDate()).format('Do MMMM YYYY')
    }
  },
  data() {
    return {
      stripeCustomerURI,
      users: [],
      sortField: 'createTimestamp',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      lastDoc: null,
      infiniteId: +new Date(),
      filterMode: '',
      filterByEmail: ''
    }
  },
  methods: {
    async loadNextPage($state) {
      try {
        let ref = (await firestore()).collection('users').limit(25)
        if (this.filterByEmail.length) {
          const endText = this.filterByEmail.replace(/.$/, c =>
            String.fromCharCode(c.charCodeAt(0) + 1)
          )
          ref = ref
            .where('email', '>=', this.filterByEmail)
            .where('email', '<', endText)
          this.sortField = 'email'
          this.sortOrder = 'asc'
        }
        switch (this.filterMode) {
          case 'paymentIssue':
            ref = ref.where('paymentState', '==', 'failed')
            break
          case 'paymentNeeded':
            ref = ref.where('paymentState', '==', 'needed')
            break
          default:
          // Eh
        }
        ref = ref.orderBy(this.sortField, this.sortOrder)
        if (this.lastDoc) ref = ref.startAfter(this.lastDoc)
        const documentSnapshots = await ref.get()
        if (documentSnapshots.docs.length) {
          this.lastDoc =
            documentSnapshots.docs[documentSnapshots.docs.length - 1]
          documentSnapshots.docs.forEach(docRef => {
            this.users.push({ id: docRef.id, ...docRef.data() })
          })
          $state.loaded()
        }
        if (documentSnapshots.docs.length < 25) $state.complete()
      } catch (error) {
        console.error(error)
        $state.error()
      }
    },
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetInfiniteScroll()
    },
    filterResults() {
      this.resetInfiniteScroll()
    },
    resetInfiniteScroll() {
      this.users = []
      this.lastDoc = null
      this.infiniteId += 1
    },
    canCreditAccount(userRecord) {
      return (
        userRecord.stripeId &&
        (userRecord.plan !== 'lifetime' || userRecord.paymentState === 'needed')
      )
    },
    async upgradeToLifetime(userRecord) {
      ;(await firestore())
        .collection('users')
        .doc(userRecord.id)
        .set({ plan: 'lifetime', freeUpgrade: true }, { merge: true })
        .then(() => {
          userRecord.plan = `lifetime`
          userRecord.freeUpgrade = true
          this.$buefy.toast.open(`Upgraded successfully!`)
        })
        .catch(err => {
          console.error(err)
          this.$buefy.toast.open(`Failed to upgrade ${userRecord.email}.`)
        })
    },
    async downgradeToFree(userRecord) {
      ;(await firestore())
        .collection('users')
        .doc(userRecord.id)
        .set(
          {
            plan: 'starter',
            freeUpgrade: firebase.firestore.FieldValue.delete()
          },
          { merge: true }
        )
        .then(() => {
          userRecord.plan = `starter`
          userRecord.paymentState = 'paid'
          delete userRecord.freeUpgrade
          this.$buefy.toast.open(`Downgraded successfully!`)
        })
        .catch(err => {
          console.error(err)
          this.$buefy.toast.open(`Failed to downgrade ${userRecord.email}.`)
        })
    },
    creditAccount(userRecord) {
      this.$buefy.dialog.prompt({
        message: 'Amount to credit',
        inputAttrs: {
          placeholder: 'Amount (cents)',
          value: 0,
          type: 'number'
        },
        onConfirm: value => {
          const castValue = Number(value)
          if (castValue && !Number.isNaN(castValue) && castValue % 1 === 0) {
            this.addAccountCredit(userRecord, castValue)
          } else {
            this.$buefy.toast.open(`Invalid amount ${castValue}`)
          }
        }
      })
    },
    addAccountCredit(userRecord, amount) {
      firebase
        .functions()
        .httpsCallable('addAccountCredit')({
          userId: userRecord.id,
          amount
        })
        .then(() => this.$buefy.toast.open('Credit added.'))
        .catch(error => {
          console.error(error)
          this.$buefy.toast.open(`Could not add credit: ${error.message}`)
        })
    },
    async markEmailValid(userRecord) {
      firebase
        .functions()
        .httpsCallable('markEmailValid')({
          userId: userRecord.id
        })
        .then(() => {
          userRecord.emailVerified = true
          userRecord.emailInvalid = false
          this.$buefy.toast.open(`Done!`)
        })
        .catch(err => {
          console.error(err)
          this.$buefy.toast.open(`Could not mark ${userRecord.email} as valid.`)
        })
    },
    async markEmailInvalid(userRecord) {
      firebase
        .functions()
        .httpsCallable('markEmailInvalid')({
          userId: userRecord.id
        })
        .then(() => {
          userRecord.emailVerified = false
          userRecord.emailInvalid = true
          this.$buefy.toast.open(`Done!`)
        })
        .catch(err => {
          console.error(err)
          this.$buefy.toast.open(
            `Could not mark ${userRecord.email} as invalid.`
          )
        })
    },
    async addAvoPrice() {
      this.$buefy.dialog.prompt({
        message: `What date?`,
        inputAttrs: {
          placeholder: 'Date',
          type: 'date',
          value: dayjs().format('YYYY-MM-DD')
        },
        trapFocus: true,
        onConfirm: date => {
          this.$buefy.dialog.prompt({
            message: `What price?`,
            inputAttrs: {
              placeholder: 'Price',
              type: 'number',
              min: '0.01',
              step: '0.01'
            },
            trapFocus: true,
            onConfirm: price => {
              firebase
                .functions()
                .httpsCallable('addAvocadoPrice')({
                  date,
                  price: Number(price)
                })
                .then(() => {
                  this.$buefy.toast.open(`Done!`)
                })
                .catch(err => {
                  console.error(err)
                  this.$buefy.toast.open(`Could not add avocado price`)
                })
            }
          })
        }
      })
    },
    resetEmail(userRecord) {
      this.$buefy.dialog.prompt({
        title: 'New email address',
        onConfirm: email => {
          firebase
            .functions()
            .httpsCallable('resetEmail')({
              userId: userRecord.id,
              email
            })
            .then(() => {
              userRecord.email = email
              this.$buefy.toast.open('Email changed.')
            })
            .catch(error => {
              console.error(error)
              this.$buefy.toast.open(`Could not reset email: ${error.message}`)
            })
        }
      })
    }
  },
  metaInfo: {
    title: 'User List'
  }
}
</script>

<style lang="sass" scoped>
.container
  padding-top: 1.5rem
.plan-name
  text-transform: capitalize
.icon
  padding-left: 0.25rem
  position: relative
  top: 0.35rem
  width: auto !important
.down-caret
  top: 0
.flex-head
  display: flex
  flex-direction: row
  h1
    flex: 1 1 auto
::v-deep
  .infinite-status-prompt
    display: flex
    align-items: center
    justify-content: center
    white-space: nowrap
    margin: 0.5rem 0.5rem 0
    &:before, &:after
      content: ""
      height: 0px
      border-top: 1px dotted #777
      flex-grow: 1
    &:before
      margin-right: 1rem
    &:after
      margin-left: 1rem
  .infinite-loading-container .infinite-status-prompt:first-of-type
    &:before, &:after
      border-top: none
  .btn-try-infinite
    margin-left: 1rem
</style>
